import { projectsData } from "../Projects/projectsData";
import "./ProjectData";

export default function ProjectDetail() {
  return (
    <>
      <section className="ProductDetail">
        
        <h2>PROJECT TITLE</h2>
        
        <h3>FEATURES</h3>
        <p>
            DESCRIPTION
        </p>
    
        <a href="/">Go back</a>
      </section>
    </>
  );
}
